import React, { Component } from 'react';
import './Body.css';

class About extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="about-container">
                <img className="right" src="./images/warehouse2.jpg" alt="[warehouse interior with yellow bins]"  />
                <p>Since 2006 we have been locating and shipping new and high-quality business and industrial surplus. We specialize in 
                hard-to-find parts from Allen Bradley, Rexroth/Bosch, Keyence, IFM, Square D, Eaton/Schneider and Sick.  We have mulitple warehouses located
                around the US and have extensive supply networks all over the world. Our primary growth has been from word of mouth, but we have also
                recently started selling some parts on ebay.</p>
                <br />
                <p>Customer Satisfaction is our top priority, and all parts are well packaged and shipped as soon as possible (daily).  Please contact us about any
                     concern you have with your purchase or part request.
                </p>
                <br />
                <p>One of our primary strengths is our hard-working parts department; they can find new replacements quickly and can cross 
                    check compatibility with other more common parts. </p>
                
                
            </div>
         );
    }
}
 
export default About;