import React, { Component } from 'react';
import './Quote.css';
import Axios from 'axios';

class Buy extends Component {
    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        
        this.handleChange = this.handleChange.bind(this);
      }
        state = {
            name: '',
            stock: this.props.stock, 
            email: '',
            message: '',
            emailStatus: false,
            sendToEmail: 'bryanswhitehead@gmail.com',
            emptyForm: false, 
            brand: this.props.brand,
            mpn: this.props.mpn
        };
    
    

        handleChange = input => e => {
            this.setState({[input]: e.target.value});
        }
    
        submitForm = (e) => {
            let {
                name, stock, phone, email, message, condition, emailStatus, emptyForm, brand, mpn
            } = this.state;

            e.preventDefault();

            if ((phone == '' || email == '')){
                this.setState({ emptyForm: true }, () => {
                    setTimeout(() => this.setState({ emptyForm: false }), 25000);
                },[])
            }
            else{
                Axios.post('http://www.nextdayelectric.com/php/sendbuy.php', this.state)
                .then(response => {
                    //console.log(response)
                    this.setState({
                        emailStatus: true
                    })
                    setTimeout(function(){
                        this.setState({emailStatus: false});
                    }.bind(this),5000);  // wait 5 seconds, then reset to false
                })
                .catch(error => {
                    console.log(error)
                })
            }
        }

render() { 
    let {
        stock, email, message, emptyForm, emailStatus, name, brand, mpn
    } = this.state;

        return (
            <div className="quote-container" onKeyDown={e => e.stopPropagation()}
                onClick={e => e.stopPropagation()}
                onFocus={e => e.stopPropagation()}
                onMouseOver={e => e.stopPropagation()}>
                {/*}
                <div className="form-left">
                <h3>Under Heavy Contruction.   Forms coming very soon!</h3><br/>
                <h3>Help Next Day Electric find your part!</h3>
                {emailStatus ? <div>Thank you.<br/><br/> We will send an invoice to your provided email.</div> : <div><p>Please be sure to enter a valid paypal email address.
                    Other payment instuctions here.</p>
                    </div>}
                </div>*/}
                
                <div className="sell-right-buy">
                    
                    <div className="input-form"><br/><br/><h2>Request an Invoice</h2><br/>Please fill in the desired quantity, your paypal email address and/or other specifics about shipping.
                    Once received we will create an invoice and send it to your paypal email address and await payment.  We wrap and ship daily.<br/><br/>
                    If you need more than the available quanitity
                     please leave a comment about how many total parts are needed and if you want the currect inventory shipped immediately.  
                    <form onSubmit={this.submitForm} className="form-horizontal">
                    <br/>
                    <table width="100%">
                        <tr>
                            <td colspan="2"><div className="prefilled-item">Brand: {this.props.brand}</div></td>
                        </tr>
                        <tr>
                            <td colspan="2"><div className="prefilled-item">MPN: {this.props.mpn}</div></td>
                        </tr>
                        
                        <tr>
                            <td width="50%"><input id="textinput" name="stock" type="text" className={emptyForm ? 'green-border' : ''} 
                            placeholder={"Quantity (up to " + this.props.stock + ")"} onChange={this.handleChange('stock')} /></td><td></td>
                        </tr>
                        <tr>
                            <td width="50%"><input id="textinput" name="name" type="text"  
                            placeholder="Your Name" onChange={this.handleChange('name')} /></td><td></td>
                        </tr>
                        <tr>
                            <td colspan="2"><input id="textinput" name="email" type="text" placeholder="Email (paypal)" 
                            className={emptyForm ? 'green-border' : ''}
                        value={email} onChange={this.handleChange('email')} /></td>
                        </tr>
                        <tr>
                            <td colspan="2">
                            <div className="sell-row-textbox">                    
                                <textarea rows="5" style={ emptyForm ? {boxShadow:'inset 0 0 5px #a52424'} : {} } id="textarea" name="textarea" className="fill-width" 
                                placeholder="Tell us more about the part(s) you need."
                                value={message} onChange={this.handleChange('message')}></textarea>
                            </div>
                            </td>
                        </tr>
                    </table>
                    
          
                        <div className="submit-row">
                            {emailStatus ? <div className="quote-complete"><strong>Thank you.  Your request was sent.</strong></div> : <div><button type="submit" className="searchButton-sell">Submit Form</button></div>}
                            
                            {emptyForm ? <div className="form-failed">Please enter a quantity and your contact information.</div> : <div> </div>}
                        </div>
                        

                    </form>
                    </div>
                </div>
            
            </div>
         );
    }
}
 
export default Buy;