import React from 'react';
import './SideDrawer.css';
import { Link } from 'react-router-dom';

const SideDrawer = props => {
    let drawerClasses = ['side-drawer'];

    if (props.show){
        drawerClasses = ['side-drawer open']
    }
    return (
    <nav className={drawerClasses}>
        <ul>
        <li><Link to="/">Search</Link></li>
        <li><Link to="/Sell">Sell</Link></li>
        <li><Link to="/About">About</Link></li>
        <li><Link to="/Faq">FAQ</Link></li>
        </ul>
    </nav>
    );
};
export default SideDrawer;