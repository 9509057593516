import React, { Component } from 'react'
import './App.css';
import Toolbar from './components/Toolbar/Toolbar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from './components/Backdrop/Backdrop';
import Body from './components/Body';
import Footer from './components/Footer';
import {BrowserRouter as Router, Switch, Link, NavLink} from 'react-router-dom';
import Route from 'react-router-dom/Route';

import Sell from './components/Sell';
import About from './components/About';
import Faq from './components/Faq';


class App extends Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }

 render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div style={{height: '100%'}}>
        <Router>
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          {backdrop}
          <Switch>
            <Route path="/" exact component={Body} />
            <Route path="/Sell" component={Sell} />
            <Route path="/About" component={About} />
            <Route path="/Faq" component={Faq} />
          </Switch>
          <Footer />
        </Router>
      </div>
    );
}
}

export default App