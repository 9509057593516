import React, { Component } from 'react';
import './Body.css';
import Axios from 'axios';
import Results from './Results';
import BrandTable from './BrandTable';
import FindPart from './FindPart';

class Body extends Component {
    constructor(props){
            super(props);
            this.onChangeSearchTerm = this.onChangeSearchTerm.bind(this);
            this.onSubmit = this.onSubmit.bind(this);
            
            this.state = {
                searchTerm: '', 
                data: []
            }
        }

        onChangeSearchTerm(e) {
            this.setState({
                searchTerm: e.target.value
            })
        };

        onSubmit(e) {
            e.preventDefault();
            const objSearch = {
                searchTerm: this.state.searchTerm
        };

            const url = 'http://www.nextdayelectric.com/php/save1.php?searchTerm=' + this.state.searchTerm;
            console.log(url);

            Axios.get(url).then(res => 
            {
                
                this.setState({data: res.data});
                   }); 
                   console.log(this.data);
            }
        

    render() { 
        return ( 
            <div className="container">
                <br/><br/><br/>
                
                <form onSubmit={this.onSubmit}>
             <div className="sb-example-1">
                <div className="search">
                    
                    <input type="text" className="searchTerm" placeholder="Search by Part Number" value={this.state.searchTerm}
                        onChange={this.onChangeSearchTerm} />
                    <button type="submit" className="searchButton-search">
                        <img src="./images/mglass.png" alt="[search icon]" />
                    </button>
                </div>
             </div>
             </form>
             <br />
             <br />
                { this.state.data.length === 0 ? 
                <div className="row1">
                    <div className="center"><h4>Not finding what you are looking for?  Give our parts department a try.</h4> 
                    <br/>
                        <div><FindPart  /></div>
                    </div>
                    
                    <br/>
                </div>
                : <div><Results data={this.state.data} />
                <div className="center"><br/><h4>Not finding what you are looking for?  Give our parts department a try.</h4> 
                    <br/>
                        <div><FindPart  /></div>
                    </div>
                    </div>  }

                { this.state.data.length === 0 ? 
                    
                    <div>
                        <BrandTable />
                        <br/>
                        
                    </div>
                    
                : <div>
                    
                 </div>}

                <br />
                
                    <br />
                <br />
                <br />
                <br />
                
            </div>
         );
    }
}
 
export default Body;