import React, { Component } from 'react';
import './Sell.css';
import Axios from 'axios';

class Sell extends Component {
    state = {
            cname: '', 
            phone: '',
            email: '',
            message: '',
            condition: '',
            emailStatus: false,
            sendToEmail: 'sales@nextdayelectric.com',
            emptyForm: false
        };
    

        handleChange = input => e => {
            this.setState({[input]: e.target.value});
        }
    
        submitForm = (e) => {
            let {
                cname, phone, email, message, condition, emailStatus, emptyForm
            } = this.state;

            e.preventDefault();

            if ((phone == '' && email == '') || message == ''){
                this.setState({ emptyForm: true }, () => {
                    setTimeout(() => this.setState({ emptyForm: false }), 25000);
                },[])
            }
            else{
                Axios.post('http://www.nextdayelectric.com/php/sendmail.php', this.state)
                .then(response => {
                    //console.log(response)
                    this.setState({
                        emailStatus: true
                    })
                    setTimeout(function(){
                        this.setState({emailStatus: false});
                    }.bind(this),5000);  // wait 5 seconds, then reset to false
                })
                .catch(error => {
                    console.log(error)
                })
            }
        }

render() { 
    let {
        cname, phone, email, message, condition, emailStatus, emptyForm
    } = this.state;

        return (
            <div className="sell-container">
                <div className="sell-left">
                {emailStatus ? <div>Thank you.<br/><br/> We will be in touch with an offer.</div> : <div><p>Do you have a collection of new, 
                    open box, or excellent condition industrial parts that you want to sell?</p><br/><br/>
                    <p>We would be happy to hear what you have available and are prepared to offer you top dollar.   
                        Please fill in this form
                        with your name and contact phone number or email along with a discription of the surplus parts.  To speed things up please provide part numbers and a description with brands
                         and condition.<br/><br/>Note: avoid using URLs or other weblinks in the input fields - the emailer will drop those.</p></div>}
                         <br/><br/><br/>
                          
                    
                </div>
                
                <div className="sell-right">
                    
                    <div className="input-form"> 
                    <form onSubmit={this.submitForm} className="form-horizontal">
                    
                        <div className="input-item">
                            <input id="textinput" name="cname" type="text" placeholder="First and Last Name" className="sell-input-text" required="" 
                            value={cname} onChange={this.handleChange('cname')} />   
                        </div>
                    

                     
                        <div className="input-item">
                            <input id="textinput" name="email" type="text" placeholder="Email Address" 
                            className={emptyForm ? 'green-border' : ''}
                        value={email} onChange={this.handleChange('email')} />   
                        </div>
                    

                    
                        <div className="input-item">
                            <input id="textinput" name="phone" type="text" placeholder="Daytime Phone"
                            className={emptyForm ? 'green-border' : ''}                            
                        value={phone} onChange={this.handleChange('phone')} />   
                        </div>
                    

                        <div className="input-item">
                            <input id="textinput" name="condition" type="text" placeholder="Condition (new, used, etc)" className="sell-input-text" required="" 
                        value={condition} onChange={this.handleChange('condition')} />   
                        </div>

                        
                        <div className="input-item"> 
                            <div className="sell-row-textbox">                    
                                <textarea rows="7" style={ emptyForm ? {boxShadow:'inset 0 0 5px #a52424'} : {} } id="textarea" name="textarea" className="fill-width" 
                                placeholder="Please give manufacturer part numbers and a description of what you would like to sell."
                                value={message} onChange={this.handleChange('message')}></textarea>
                            </div>
                        </div>
          
                        <div className="submit-row">
                            <div><button type="submit" className="searchButton-sell">Submit Form</button></div>
                            {emptyForm ? <div className="form-failed">Please enter your email or phone number plus a message.</div> : <div> </div>}
                        </div>
                        

                    </form>
                    </div>
                </div>
            
            </div>
         );
    }
}
 
export default Sell;