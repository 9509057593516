import React from 'react';
import './DrawerToggleButton.css';

const drawerToggleButton = props => (
    <button className="toggle-button" onClick={props.click}>
        <div className="toggleButton_line"></div>
        <div className="toggleButton_line"></div>
        <div className="toggleButton_line"></div>
    </button>

);


export default drawerToggleButton;