import React from 'react';
import './Toolbar.css';
import '../SideDrawer/DrawerToggleButton';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import { Link } from 'react-router-dom';

const Toolbar = props => (
    <header className="toolbar">
        <nav className="toolbar__navigation">
            <div className="toolbar__logo"><a href="/"><img src="../images/next_day_electric_logo_sm.png" 
                alt="[ Next Day Electric Navbar Logo ]" /></a>
            </div>
            <div className="spacer"> </div>
            <div className="toolbar__nav-items">
                <ul>
                    <li><Link to="/">Search</Link></li>
                    <li><Link to="/Sell">Sell</Link></li>
                    <li><Link to="/About">About</Link></li>
                    <li><Link to="/Faq">FAQ</Link></li>
                </ul>
            </div>
            <div className="burger_button">
                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
        </nav>
    </header>
);

export default Toolbar;