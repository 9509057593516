import React, { Component } from 'react';
import './Body.css';

class Faq extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="faq-container">
                
                <p className="faq-question">What methods of payment do you take?</p>
                <p className="faq-answer">We currently accept paypal.  Once an order has been made we will
                email you a paypal invoice.  In some situations we can make arrangements for other payment
                methods.</p>

                <p className="faq-question">How do you handle shipping?</p>
                <p className="faq-list">
                    <ul>
                        <li>We ship UPS and USPS daily (M-F) and use other freight carriers as needed.  For international deliveries we use UPS and DHL.  Our staff has been shipping 
                            packages for decades and we will make sure your parts are packed well and get to you on time.</li>
                            <li>Please verify that your paypal address is correct for your protection as we do not ship to unregistered addresses.</li>
                            <li>Special delivery arrangements can be made as needed (e.g., UPS for Canadian Customers) and we insure deliveries over $200.</li>
                    </ul>
                </p>

                <p className="faq-question">What is your return policy?</p>
                <p className="faq-answer">We offer a standard 30-day return policy on items that are defective or otherwise do not match the ebay description.  We carefully control and monitor 
				the condition of our items and part swapping or tampering with items will nullify our return policy and no refund will be issued.  Returned items will be 
				reviewed and serial numbers verified.  If we have a replacement one will be shipped immediately otherwise a refund will be made which does not exceed the purchase price.
                </p>

                <p className="faq-question">What is the best way to get ahold of an ND sales representative?</p>
                <p className="faq-list">
                    <ul>
                        <li>Email us anytime: sales@nextdayelectric.com or use one of the contact forms found on the search or sell pages.</li>
                        <li>Call us during normal work hours (9am - 5pm) EST at <strong>1-614-NEX-TDAY</strong> (1-614-639-8329). </li>
                    </ul>                        
                </p>

                
                

            </div>
         );
    }
}
 
export default Faq;