import React, { Component } from 'react';
import useModal from 'react-hooks-use-modal';
import Part from './Part';
import './Quote.css';

function Locate ({ id, mpn, brand, price, title, stock }) {
    const [Modal, open, close, isOpen] = useModal('root', {
        preventScroll: true
      });

   
        return ( 
            <div>
                <div className="resultButton-locate" onClick={open}><span className="vert-middle">Locate Part</span></div>
                    <Modal>
                        <div className="result-form">
                            
                            <Part id={id} mpn={mpn} brand={brand} price={price} 
                                title={title} stock={stock} />
                            <div className="resultButton-close" onClick={close}>Close Form</div>
                        </div>
                    </Modal>
            </div>
         );
    
}
 
export default Locate;