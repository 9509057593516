import React from 'react';
import './ResultRow.css';
import useModal from 'react-hooks-use-modal';
import Locate from './Locate';
import GetQuote from './GetQuote';
import Buy from './Buy';

function ResultRow({ id, mpn, brand, price, title, stock }) {
    const [Modal, open, close, isOpen] = useModal('root', {
        preventScroll: true
      });

    return ( 
        <tr>
            <td key={id}>{mpn}</td>
            <td>{brand}</td>
            <td>{price == 0 || price == ''? <div>
            <GetQuote id={id} mpn={mpn} brand={brand} price={price} 
                title={title} stock={stock} />
            </div>
            :
            <div>${price}</div>}</td>
            <td className="td-title">{title}</td>
            <td className="td-stock">{stock == '0' ? 
                <div>
                   <span className="sold-out">Sold Out</span>
                </div> 
                : 
                <div><span className="available">{stock} </span><span style={{color: "#999999"}}>qty.</span>
                </div>
                }
            </td>
            <td>{stock == '0' ?
                <div>{price == 0 || price == '' || price === '0.00' ? 
                    <div><GetQuote id={id} mpn={mpn} brand={brand} price={price} 
                    title={title} stock={stock} /></div> 
                    : 
                    <div><Locate id={id} mpn={mpn} brand={brand} price={price} title={title} stock={stock} /></div>
                    }
                    </div>
                :
                <div>
                <div className="resultButton-buy" onClick={open} ><span className="vert-middle">Buy Now</span></div>
                    <Modal>
                    <div className="result-form">
                            
                            <Buy id={id} mpn={mpn} brand={brand} price={price} 
                                title={title} stock={stock} />
                            <div className="resultButton-close" onClick={close}>Close Form</div>
                        </div>
                    </Modal>
                </div>}
                </td>
        </tr>
     );
}

 
export default ResultRow;