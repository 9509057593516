import { render } from '@testing-library/react';
import React, {Component} from 'react';
import ResultRow from './ResultRow'

function Results({ data }) {
    return ( 
        <div>
        <table className="results_table">
                    <thead>
                    <tr>
                    <th>MPN</th>
                    <th>Brand</th>
                    <th>Price</th>
                    <th>Description</th>
                    <th>Stock</th>
                    <th></th>
                    </tr>
                    </thead>
                    <tbody>
                
                    {data.map((result) => {
                    return ( <ResultRow id={result.id} mpn={result.mpn} brand={result.brand} price={result.price} title={result.title} stock={result.stock} />)
                    })}
                    </tbody>
                    </table>
        

        </div>
     );
  
}

 
export default Results;