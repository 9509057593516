import React from 'react';
import './BrandTable.css';




function BrandTable(){
    return(
        <div className="brands-list">
                    <div className="brand_col">
                        ABB<br/>
                        <span className="color-animate-1">Allen Bradley</span><br/>
                        Baldor<br/>
                        Balluff<br/>
                        Banner<br/>
                        Brad Harrison<br/>
                        Bussmann<br/>
                        Cutler Hammer<br/>
                        <span className="color-animate-2">Fanuc</span><br/>
                    </div>
                    <div className="brand_col">
                        Festo<br/>
                        Fuji Electric<br/>
                        GE<br/>
                        GE Fanuc<br/>
                        Graco<br/>
                        Honeywell<br/>
                        Hubbell<br/>
                        <span className="color-animate-3">IMF</span><br/>
                        IFM Efector<br/>
                    </div>
                    <div className="brand_col">
                        <span className="color-animate-5">Keyence</span><br/>
                        Mac<br/>
                        Mitsubishi<br/>
                        Numatics<br/>
                        Omron<br/>
                        Parker<br/>
                        PepperL+Fuchs<br/>
                        Phoenix Contact<br/>
                        Pilz<br/>
                    </div>
                    <div className="brand_col">
                        Rexroth<br/>
                        Sick<br/>
                        Siemens<br/>
                        SMC<br/>
                        Square D<br/>
                        Telemecanique<br/>
                        Turck<br/>
                        Yaskawa<br/>
                        <span className="color-animate-4">Yaskawa Motoman</span><br/>
                    </div>
                </div>
    )
}

export default BrandTable;