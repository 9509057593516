import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
    state = {  }
    render() { 
        return (
            <footer className="footer_container"><p className="footer-text"><strong>1-614-NEX-TDAY (1-614-639-8329) (9am - 5pm) EST</strong></p>
            <p>sales@nextdayelectric.com</p></footer>
            
         );
    }
}
 
export default Footer;